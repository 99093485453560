import actions from './actions';
import cvTechActions from '../agency-redux/cvTheque/actions';
import authActions from '../authentification/actions';

const {
    GET_APPLICATIONS_LIST,
    WORK_PERMIT_LIST,
    GET_PLATFORM_SUCCURSALE,
    CONSULTANT,
    SET_FIRST_FORM,
    SET_SECOND_FORM,
    CURRENT_PAGE,
    PAGE_SIZE,
    TOTAL_ITEMS,
    SUBMITTED_FIRST_FILTER_FORM,
    SUBMITTED_SECOND_FILTER_FORM,
    TOGGLE_DRAWER,
    SELECTED_CANDIDATURES,
    SELECTED_CANDIDATURES_STATUS,
    RESET_APPLICATIONS_STATE,
} = actions;
const { LIST_MISSIONS_ACTION } = cvTechActions;
const { LOCATIONS_LIST } = authActions;

const initialState = {
    applicationsList: null,
    firstFilterForm: {
        plateform: null,
        succursale: null,
        consultant: null,
        missionId: null,
    },
    secondFilterForm: {
        candidateStatus: null,
        status: null,
        interest: null,
        needle: undefined,
        work_permit: null,
        permit: null,
        location: null,
        age: null,
        preavis: null,
    },
    filters: [],
    currentPage: 1,
    pageSize: 10,
    totalItems: 0,
    submittedFirstFilterForm: {},
    submittedSecondFilterForm: {},
    drawer: {
        isOpen: false,
        data: {},
    },
    selectedCandidatures: [],
    selectedCandidaturesStatus: null,
};

const applicationsReducer = (state = initialState, action) => {
    const { type, data } = action;

    switch (type) {
        case GET_APPLICATIONS_LIST:
            return {
                ...state,
                applicationsList: data,
            };

        case WORK_PERMIT_LIST:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    work_permit: data.map((permit) => ({ label: permit.description, value: permit.id })),
                },
            };

        case GET_PLATFORM_SUCCURSALE:
            return {
                ...state,
                filters: { ...state.filters, platformAndSuccursale: data },
            };

        case CONSULTANT:
            return {
                ...state,
                filters: { ...state.filters, consultant: data },
            };
        case LIST_MISSIONS_ACTION:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    missions: data.map((mission) => ({ label: mission.label, value: mission.value })),
                },
            };
        case LOCATIONS_LIST:
            return {
                ...state,
                filters: { ...state.filters, location: data },
            };
        case SET_FIRST_FORM:
            return {
                ...state,
                firstFilterForm: { ...state.firstFilterForm, ...data },
            };
        case SET_SECOND_FORM:
            return {
                ...state,
                secondFilterForm: { ...state.secondFilterForm, ...data },
            };
        case CURRENT_PAGE:
            return {
                ...state,
                currentPage: data,
            };
        case PAGE_SIZE:
            return {
                ...state,
                pageSize: data,
            };
        case TOTAL_ITEMS:
            return {
                ...state,
                totalItems: data,
            };
        case SUBMITTED_FIRST_FILTER_FORM:
            return {
                ...state,
                submittedFirstFilterForm: data,
            };
        case SUBMITTED_SECOND_FILTER_FORM:
            return {
                ...state,
                submittedSecondFilterForm: data,
            };
        case TOGGLE_DRAWER:
            return {
                ...state,
                drawer: { data: state.drawer.isOpen ? { ...state.drawer.data } : data, isOpen: !state.drawer.isOpen },
            };
        case SELECTED_CANDIDATURES:
            return {
                ...state,
                selectedCandidatures: data,
            };
        case SELECTED_CANDIDATURES_STATUS:
            return {
                ...state,
                selectedCandidaturesStatus: data,
            };
        case RESET_APPLICATIONS_STATE:
            return initialState;
        default:
            return state;
    }
};

export default applicationsReducer;
