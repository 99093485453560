import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

/* set token */
export const setToken = (token) => {
    localStorage.setItem('token', token);
};

/* set refresh token */
export const setRefreshToken = (token) => {
    localStorage.setItem('refreshToken', token);
};

/* get token */
export const getToken = () => {
    const token = localStorage.getItem('token') || null;
    return token;
};

/* get refresh token */
export const getRefreshToken = () => {
    const token = localStorage.getItem('refreshToken') || null;
    return token;
};

/* remove token */
export const removeToken = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
};

/* get Current Current User */
export const getUser = () => {
    const token = localStorage.getItem('token');
    return token ? jwtDecode(token) : null;
};

export const getUserType = () => {
    return localStorage.getItem('userType') || null;
};

/* get Id Current User */
export const getUserId = () => {
    const user = getUser();
    return user?.id;
};

/* get login status */
export const getLoginStatus = () => {
    const user = getUser();
    return user?.statusAccountDisactivation;
};

/* set role Current User */
export const setUserRole = (role) => {
    localStorage.setItem('role', role);
};

/* get Roles Current User */
export const getUserRole = () => {
    const user = getUser();
    return user?.roles;
};

/* get if token is valid */
export const getValidationToken = () => {
    const user = getUser();
    if (new Date() > new Date(user.exp * 1000)) {
        Cookies.remove('logedIn');
        Cookies.remove('token');
    }
};

export const getTokenFromCookies = () => {
    return Cookies.get('token');
};

/*  User is Admin */
export const isAdmin = () => {
    const Roles = getUserRole();
    return Roles?.includes('ROLE_ADMIN') ? true : Cookies.remove('logedIn');
};

/* User is Candidate */
export const isCandidate = () => {
    const Roles = getUserRole();
    if (Roles && Roles.length) {
        return Roles.includes('ROLE_CANDIDAT');
    }
};

/* User is Client */
export const isClient = () => {
    const Roles = getUserRole();
    if (Roles && Roles.length) {
        return Roles.includes('ROLE_CLIENT');
    }
};

/* User is Client */
export const isConsultant = () => {
    const Roles = getUserRole();
    if (Roles && Roles.length) {
        return Roles.includes('ROLE_CONSULTANT');
    }
};

/* User is Client */
export const isManager = () => {
    const Roles = getUserRole();
    if (Roles && Roles.length) {
        return Roles.includes('ROLE_MANAGER');
    }
};

/* get if token is valid */
export const tokenExpiration = () => {
    const user = getUser();
    if (user) {
        if (new Date() < new Date(user.exp * 1000)) {
            return true;
        } else return false;
    } else return false;
};

export const getUserEmail = () => {
    const user = getUser();

    return user?.username;
};

export const getUserRoleLevel = () => {
    if (isAdmin() || isConsultant()) return 'ADMIN_CONSULTANT';
    if (isManager() || isClient()) return 'MANAGER_CLIENT';

    return null;
};
