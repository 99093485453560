const actions = {
    GET_APPLICATIONS_LIST: 'GET_APPLICATIONS_LIST',
    UPDATE_INTEREST: 'UPDATE_INTEREST',
    UPDATE_STATUS: 'UPDATE_STATUS',
    WORK_PERMIT_LIST: 'WORK_PERMIT_LIST',
    GET_PLATFORM_SUCCURSALE: 'GET_PLATFORM_SUCCURSALE',
    CONSULTANT: 'CONSULTANT',
    SET_FIRST_FORM: 'SET_FIRST_FORM',
    SET_SECOND_FORM: 'SET_SECOND_FORM',
    CURRENT_PAGE: 'CURRENT_PAGE',
    PAGE_SIZE: 'PAGE_SIZE',
    TOTAL_ITEMS: 'TOTAL_ITEMS',
    SUBMITTED_FIRST_FILTER_FORM: 'SUBMITTED_FIRST_FILTER_FORM',
    SUBMITTED_SECOND_FILTER_FORM: 'SUBMITTED_SECOND_FILTER_FORM',
    TOGGLE_DRAWER: 'TOGGLE_DRAWER',
    SELECTED_CANDIDATURES: 'SELECTED_CANDIDATURES',
    SELECTED_CANDIDATURES_STATUS: 'SELECTED_CANDIDATURES_STATUS',
    RESET_APPLICATIONS_STATE: 'RESET_APPLICATIONS_STATE',

    resetApplicationStateAction: (data) => {
        return {
            type: actions.RESET_APPLICATIONS_STATE,
            data: data,
        };
    },

    /* get applications list */
    getApplicationsListAction: (data) => {
        return {
            type: actions.GET_APPLICATIONS_LIST,
            data: data,
        };
    },

    /* update applications interest */
    updateInterestAction: (data) => {
        return {
            type: actions.UPDATE_INTEREST,
            data: data,
        };
    },

    /* update applications status */
    updateStatusAction: (data) => {
        return {
            type: actions.UPDATE_STATUS,
            data: data,
        };
    },

    /* get work permit list */
    getWorkPermitListAction: (data) => {
        return {
            type: actions.WORK_PERMIT_LIST,
            data: data,
        };
    },

    /* get succursale & platform list */
    getSuccursaleAndPlatformAction: (data) => {
        return {
            type: actions.GET_PLATFORM_SUCCURSALE,
            data: data,
        };
    },

    /* get consultant list */
    getConsultantAction: (data) => {
        return {
            type: actions.CONSULTANT,
            data: data,
        };
    },

    //Setting first filter form
    setFirstFormAction: (data) => {
        return {
            type: actions.SET_FIRST_FORM,
            data: data,
        };
    },

    setSecondFormAction: (data) => {
        return {
            type: actions.SET_SECOND_FORM,
            data: data,
        };
    },

    setCurrentPageAction: (data) => {
        return {
            type: actions.CURRENT_PAGE,
            data: data,
        };
    },

    setPageSizeAction: (data) => {
        return {
            type: actions.PAGE_SIZE,
            data: data,
        };
    },

    setTotalItemsAction: (data) => {
        return {
            type: actions.TOTAL_ITEMS,
            data: data,
        };
    },

    submitFirstFilterAction: (data) => {
        return {
            type: actions.SUBMITTED_FIRST_FILTER_FORM,
            data: data,
        };
    },

    submitSecondFilterAction: (data) => {
        return {
            type: actions.SUBMITTED_SECOND_FILTER_FORM,
            data: data,
        };
    },
    toggleDrawerAction: (data) => {
        return {
            type: actions.TOGGLE_DRAWER,
            data: data,
        };
    },
    setSelectedCandidaturesToSendResult(data) {
        return {
            type: actions.SELECTED_CANDIDATURES,
            data: data,
        };
    },
    setSelectedCandidaturesStatus(data) {
        return {
            type: actions.SELECTED_CANDIDATURES_STATUS,
            data: data,
        };
    },
};

export default actions;
