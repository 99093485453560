const actions = {
    LIST_MISSIONS: 'LIST_MISSIONS',
    LIST_MISSIONS_ACTION: 'LIST_MISSIONS_ACTION',
    LIST_FOLDER: 'LIST_FOLDER',
    NEW_MISSION: 'NEW_MISSION',
    NEW_LINK: 'NEW_LINK',
    NEW_FAVORIS: 'NEW_FAVORIS',
    NEW_FOLDERS: 'NEW_FOLDERS',
    NEW_INTERST: 'NEW_INTERST',
    ARCHIVAGE: 'ARCHIVAGE',
    DISABLE: 'DISABLE',
    TAGS: 'TAGS',
    POST_TAGS: 'POST_TAGS',
    SUCCURSALE: 'SUCCURSALE',
    LIST_EMAIL: 'LIST_EMAIL',
    ACTIVITY: 'ACTIVITY',
    COMMENT: 'COMMENT',
    ADD_COMMENT: 'ADD_COMMENT',
    EDIT_FAVORIS: 'EDIT_FAVORIS',
    CHECK_EMAIL: 'CHECK_EMAIL',
    DRAWER_CANDIDATE_DETAILS: 'DRAWER_CANDIDATE_DETAILS',
    UPDATE_COMMENT: 'UPDATE_COMMENT',
    DELETE_COMMENT: 'DELETE_COMMENT',
    ADD_CLIENT_COMMENT: 'ADD_CLIENT_COMMENT',
    LIST_CANDIDATES_ELASTIC_SEARCH: 'LIST_CANDIDATES_ELASTIC_SEARCH',
    SET_LIST_SEARCHED_SELECTED: 'SET_LIST_SEARCHED_SELECTED', //

    checkWeek: (data) => {
        return {
            type: actions.CHECK_EMAIL,
            data,
        };
    },

    editFavoris: (data) => {
        return {
            type: actions.EDIT_FAVORIS,
            data,
        };
    },

    addComment: (data) => {
        return {
            type: actions.ADD_COMMENT,
            data,
        };
    },

    comment: (data) => {
        return {
            type: actions.COMMENT,
            data,
        };
    },

    activity: (data) => {
        return {
            type: actions.ACTIVITY,
            data,
        };
    },

    listEmail: (data) => {
        return {
            type: actions.LIST_EMAIL,
            data,
        };
    },

    listSuccursales: (data) => {
        return {
            type: actions.SUCCURSALE,
            data,
        };
    },

    postTags: (data) => {
        return {
            type: actions.POST_TAGS,
            data,
        };
    },

    listTags: (data) => {
        return {
            type: actions.TAGS,
            data,
        };
    },

    disable: (data) => {
        return {
            type: actions.DISABLE,
            data,
        };
    },

    NewInterest: (data) => {
        return {
            type: actions.NEW_INTERST,
            data,
        };
    },

    newFolders: (data) => {
        return {
            type: actions.NEW_FOLDERS,
            data,
        };
    },

    newFavoris: (data) => {
        return {
            type: actions.NEW_FAVORIS,
            data,
        };
    },

    newMissions: (data) => {
        return {
            type: actions.NEW_MISSION,
            data,
        };
    },

    newLink: (data) => {
        return {
            type: actions.NEW_LINK,
            data,
        };
    },

    listMissions: (data) => {
        return {
            type: actions.LIST_MISSIONS,
            data,
        };
    },

    listMissionsAction: (data) => {
        return {
            type: actions.LIST_MISSIONS_ACTION,
            data,
        };
    },

    listFolder: (data) => {
        return {
            type: actions.LIST_FOLDER,
            data,
        };
    },

    archiver: (data) => {
        return {
            type: actions.ARCHIVAGE,
            data,
        };
    },

    getDrawerCandidateDetails: (data) => {
        return {
            type: actions.DRAWER_CANDIDATE_DETAILS,
            data,
        };
    },

    updateComment: (data) => {
        return {
            type: actions.UPDATE_COMMENT,
            data,
        };
    },

    deleteComment: (data) => {
        return {
            type: actions.DELETE_COMMENT,
            data,
        };
    },

    addClientComment: (data) => {
        return {
            type: actions.ADD_CLIENT_COMMENT,
            data,
        };
    },

    listCandidatesElasticSearchAction: (data) => {
        return {
            type: actions.LIST_CANDIDATES_ELASTIC_SEARCH,
            data,
        };
    },

    setSavedSearchSelectedR: (data) => {
        return {
            type: actions.SET_LIST_SEARCHED_SELECTED,
            data,
        };
    },
};

export default actions;
